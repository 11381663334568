.meat-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-right: 1.5rem;
}

.meat-type label {
    color: #4a5568;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.butchers {
    display: flex;
    align-items: center;
}

.butcher {
    display: flex;
    align-items: center;
    padding: 2px 4px;
    border-radius: 50px;
    background: #4a556815;
    font-size: 14px;
    font-weight: 600;
}

.butcher.halal {
    color: white;
}

.butcher img {
    width: 14px;
    margin-left: 4px;
}

.butcher:not(:last-child) {
    margin-right: 0.35rem;
}

.detail-pill, .butcher {
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px !important;
    white-space: nowrap;
    display: flex;
    align-items: center;
    max-height: 32px;
}
